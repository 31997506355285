:root {
  --primary-1000: #06281f;
  --primary-900: #084030;
  --primary-800: #315450;
  --primary-200: #51746b;
  --primary-100: #d9dedd;

  --secondary-1000: #047364;
  --secondary-500: #7bb3ab;
  --secondary-100: #dfe8e7;

  --tertiary-1000: #ffe870;

  --default-700: #4e4c4c;
  --default-600: #656363;
  --default-400: #9c999a;
  --default-300: #bbb8b9;
  --default-200: #d5d3d4;
  --default-100: #eae9e9;

  --surface: #ffffff;
  --accent: #05d48e;
  --accent-light: #e7f6ed;

  --background: #dceee9;

  --info-color: #d0e1dd;
  --info-background: #f3f2f2;
  --success-color: #049a78;
  --success-background: #e6f5f2;
  --warning-color: #fac420;
  --warning-background: #fef9eb;
  --error-color: #fc3f48;
  --error-background: #fceded;

  --elevation-1: 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
