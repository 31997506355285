:root {
  --primary-1000: #06281f;
  --primary-900: #084030;
  --primary-800: #315450;
  --primary-200: #51746b;
  --primary-100: #d9dedd;
  --secondary-1000: #047364;
  --secondary-500: #7bb3ab;
  --secondary-100: #dfe8e7;
  --tertiary-1000: #ffe870;
  --default-700: #4e4c4c;
  --default-600: #656363;
  --default-400: #9c999a;
  --default-300: #bbb8b9;
  --default-200: #d5d3d4;
  --default-100: #eae9e9;
  --surface: #fff;
  --accent: #05d48e;
  --accent-light: #e7f6ed;
  --background: #dceee9;
  --info-color: #d0e1dd;
  --info-background: #f3f2f2;
  --success-color: #049a78;
  --success-background: #e6f5f2;
  --warning-color: #fac420;
  --warning-background: #fef9eb;
  --error-color: #fc3f48;
  --error-background: #fceded;
  --elevation-1: 0px 1px 1px #00000024, 0px 2px 1px #0000001f, 0px 1px 3px #0003;
}

@font-face {
  font-family: Peachi;
  src: url("Peachi-Regular.3cf6b0ff.otf");
  font-style: "normal";
  font-display: "swap";
  font-weight: 400;
}

@font-face {
  font-family: Peachi;
  src: url("Peachi-Bold.e5c01ebb.otf");
  font-style: "normal";
  font-display: "swap";
  font-weight: 800;
}

@font-face {
  font-family: Peachi;
  src: url("Peachi-Medium.1458526a.otf");
  font-style: "normal";
  font-display: "swap";
  font-weight: 600;
}

@font-face {
  font-family: Peachi;
  src: url("Peachi-Thin.d3fbc6b4.otf");
  font-style: "normal";
  font-display: "swap";
  font-weight: 200;
}

@font-face {
  font-family: Jokker;
  src: url("Jokker-Regular.6b2944b3.otf");
  font-style: "normal";
  font-display: "swap";
  font-weight: 400;
}

@font-face {
  font-family: Jokker;
  src: url("Jokker-Semibold.f6bc256f.otf");
  font-style: "normal";
  font-display: "swap";
  font-weight: 600;
}

h1, .h1 {
  letter-spacing: .18px;
  color: var(--primary-1000);
  font-family: Peachi;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

h2, .h2 {
  letter-spacing: .18px;
  color: var(--primary-1000);
  font-family: Peachi;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

h3, .h3 {
  letter-spacing: .18px;
  color: var(--primary-1000);
  font-family: Peachi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

h4, .h4 {
  letter-spacing: .4px;
  color: var(--primary-1000);
  font-family: Jokker;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

p {
  letter-spacing: .4px;
  color: var(--primary-1000);
  font-family: Jokker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.caption {
  letter-spacing: .4px;
  color: var(--primary-1000);
  font-family: Jokker;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  line-height: 1.15;
}

body {
  min-height: 100vh;
  background-color: var(--background);
  margin: 0;
  font-family: Peachi;
}

#root {
  min-height: 100vh;
  position: relative;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul, ol {
  padding-inline-start: 40px;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/*# sourceMappingURL=index.c57a3bcd.css.map */
