html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS */
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Peachi'; /* To have if we remove mui*/
  background-color: var(--background); /* To have if we remove mui*/
}

#root {
  min-height: 100vh;
  position: relative;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul,
ol {
  padding-inline-start: 40px;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

/*
    Correct the inability to style clickable types in iOS and Safari.
  */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/*
    Remove the inner border and padding in Firefox.
  */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/*
    Restore the focus styles unset by the previous rule.
  */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/*
    Correct the cursor style of increment and decrement buttons in Chrome.
  */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/*
   Correct the odd appearance in Chrome and Safari.
   Correct the outline style in Safari.
  */
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/*
   Remove the inner padding in Chrome and Safari on macOS.
  */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
   Correct the inability to style clickable types in iOS and Safari.
   Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
