@font-face {
  font-family: 'Peachi';
  src: url('../fonts/Peachi-Regular.otf');

  font-style: 'normal';
  font-display: 'swap';
  font-weight: 400;
}

@font-face {
  font-family: 'Peachi';
  src: url('../fonts/Peachi-Bold.otf');
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 800;
}

@font-face {
  font-family: 'Peachi';
  src: url('../fonts/Peachi-Medium.otf');
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 600;
}

@font-face {
  font-family: 'Peachi';
  src: url('../fonts/Peachi-Thin.otf');
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 200;
}

@font-face {
  font-family: 'Jokker';
  src: url('../fonts/Jokker-Regular.otf');
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 400;
}

@font-face {
  font-family: 'Jokker';
  src: url('../fonts/Jokker-Semibold.otf');
  font-style: 'normal';
  font-display: 'swap';
  font-weight: 600;
}

h1,
.h1 {
  font-family: 'Peachi';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.18px;
  color: var(--primary-1000);
}

h2,
.h2 {
  font-family: 'Peachi';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: var(--primary-1000);
}

h3,
.h3 {
  font-family: 'Peachi';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.18px;
  color: var(--primary-1000);
}

h4,
.h4 {
  font-family: 'Jokker';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--primary-1000);
}

p {
  font-family: 'Jokker';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--primary-1000);
}

.caption {
  font-family: 'Jokker';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--primary-1000);
}
